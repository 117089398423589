import React from "react";
import globoCard from "../../images/illustrations/gift-card.webp";
import PrimaryButton from "../buttons/PrimaryButton/index";
import BlueCircleParticle from "../particles/BlueCircleParticle";
import OrangeCircleParticle from "../particles/OrangeCircleParticle";
import StarParticle from "../particles/StarParticle";
// import PurpleCircleParticle from "../particles/PurpleCircleParticle";

export default function HeroSection() {
  return (
    <section id="home" className="relative bg-primary pt-[140px] bg-opacity-5 pb-24">
      <span className="absolute bg-purple-400 -left-28 -top-28 rounded-full opacity-[20%] blur-3xl aspect-square h-[350px] -z-10 animate-pulse" />
      <span className="absolute bg-gradient-to-br from-primary to-secondary -right-28 -bottom-28 rounded-full opacity-[15%] blur-3xl  delay-700 duration-1000 aspect-square h-[550px] -z-10" />

      <div className="container grid px-4 mx-auto md:grid-cols-2">
        <div className="flex items-center">
          <div className="relative ml-4">
            <StarParticle className="absolute top-0 right-0" />
            {/* <PurpleCircleParticle className="absolute bottom-0 -left-12" /> */}
            <h1 className="text-4xl font-bold leading-normal text-white md:text-5xl lg:text-6xl">
            Effortlessly<br /> 
              <span className="text-blue-gradient">Validate Your Gift's</span> <br />
              Authenticity
            </h1>
            <div className="mt-4 mb-8 text-[18px]">
              <p className="text-white">
              Validate Your Giftcard Authenticity with Confidence. 
              Enjoy Effortless Validation to Ensure Your Gift 
              Card Delivers the Perfect Surprise.
              Trust, Validate, and Make Every Gift Memorable. you can 
              also use our website to check if your giftcard has been activated.
              </p>
              <img className="md:hidden" src={globoCard} alt="card" />
            </div>
            
            <div  className="col-span-2 gap-4 lg:flex lg:mb-12">
            <a href="#validate">
              <PrimaryButton  className="w-full mb-2 lg:w-auto lg:mb-0">
            Validate card
              </PrimaryButton>
              </a>
            </div>
            
          </div>
        </div>
        <div className="relative hidden md:block">
          <BlueCircleParticle className="absolute top-0 left-11 duration-[5s]" />
          <OrangeCircleParticle className="absolute right-0 bottom-1/4" />

          <img src={globoCard} alt="card" />
        </div>
      </div>
    </section>
  );
}
