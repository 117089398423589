import React, { useEffect } from "react";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./LandingPageFour.css";
import invertcommas from "../images/particles/star1.svg";

import photo1 from "../../assets/humanimages/first.jpg";
import photo2 from "../../assets/humanimages/second.jpeg";
import photo3 from "../../assets/humanimages/third.jpg";
import photo4 from "../../assets/humanimages/fourth.jpg";

const LandingPageFour = () => {
  useEffect(() => {
    new Swiper(".mySwiper", {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      grabCursor: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  return (
    <section id="testimonial" className="LandingPage-Section">
      <div className="testimonial mySwiper">
        <h1 className="LandingPage-SectionH1">Testimonials</h1>
        &nbsp;
        <img
          style={{ margin: "auto" }}
          src={invertcommas}
          width="50px"
          alt=""
          className=""
        />
        &nbsp; &nbsp; &nbsp;
        <div
          style={{ height: "450px", marginTop: "20px" }}
          className="testi-content swiper-wrapper "
        >
          <div className="slide swiper-slide">
            <img src={photo1} alt="" className="image" />
            <p className="TextSlides">
            I am incredibly impressed with the seamless experience provided by Validate's Gift Card 
            Validation Site. As someone who values the authenticity of gift cards, Validate has become 
            my go-to platform for verifying and ensuring the legitimacy of my gift card purchases
            </p>
            <i className="bx bxs-quote-alt-left quote-icon"></i>
            <div className="details">
              <span className="name">Karen Lynn</span>
            </div>
          </div>
          <div className="slide swiper-slide">
            <img src={photo2} alt="" className="image" />
            <p className="TextSlides">
            What sets Validatecard apart is not just its cutting-edge technology but also the commitment to user security. 
            The site's dedication to ensuring that each redemption code is the original scratched code adds an extra layer
            of assurance
            </p>
            <i className="bx bxs-quote-alt-left quote-icon"></i>
            <div className="details">
              <span className="name">JSubash Rajendran</span>
            </div>
          </div>
          <div className="slide swiper-slide">
            <img src={photo3} alt="" className="image" />
            <p className="TextSlides">
            The success modal, coupled with real-time loading indicators, provides a sense of reassurance and reliability.
             It's evident that Validate prioritizes user experience and transparency, making the validation journey a 
             seamless one.
            </p>
            <i className="bx bxs-quote-alt-left quote-icon"></i>
            <div className="details">
              <span className="name">Sarah Lee</span>
            </div>
          </div>
          <div className="slide swiper-slide">
            <img src={photo4} alt="" className="image" />
            <p className="TextSlides">
            Validate's Gift Card Validation Site has truly transformed my gift-giving experience. 
            The intuitive design of the platform, coupled with its swift and accurate validation process, 
            has made it my preferred choice for ensuring the legitimacy of gift cards.
            </p>
            <i className="bx bxs-quote-alt-left quote-icon"></i>
            <div className="details">
              <span className="name">Michael Smith</span>
            </div>
          </div>
        </div>
        <div className="swiper-button-next nav-btn"></div>
        <div className="swiper-button-prev nav-btn"></div>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default LandingPageFour;
