export const Currency = [
	{
	  value: "USD",
	  label: "USD",
	},
	{
	  value: "GBP",
	  label: "GBP",
	},
	{
	  value: "AUD",
	  label: "AUD",
	},
	{
	  value: "EUR",
	  label: "EUR",
	},
	{
	  value: "CAD",
	  label: "CAD",
	},
  ];
  
 export const Card = [
	{
	  value: "Apple Giftcard",
	  label: "Apple Giftcard",
	},
	{
	  value: "Amazon Giftcard",
	  label: "Amazon Giftcard",
	},
	{
	  value: "Steam  Giftcard",
	  label: "Steam Giftcard",
	},
	{
	  value: "Nike Giftcard",
	  label: "Nike Giftcard",
	},
	{
	  value: "eBay Giftcard",
	  label: "eBay Giftcard",
	},
	{
	  value: "Xbox Giftcard",
	  label: "Xbox Giftcard",
	},
	{
	  value: "iTunes Giftcard",
	  label: "iTunes Giftcard",
	},
	{
	  value: "Google Play Giftcard",
	  label: "Google Play Giftcard",
	},
	{
	  value: "Vanilla  Giftcard",
	  label: "Vanilla  Giftcard",
	},
	{
	  value: "Play Station  Giftcard",
	  label: "Play Station  Giftcard",
	},
	{
	  value: "Sephora  Giftcard",
	  label: "Sephora  Giftcard",
	},
	{
	  value: "Netflix  Giftcard",
	  label: "Netflix  Giftcard",
	},
	{
	  value: "Nordstrom Giftcard",
	  label: "Nordstrom  Giftcard",
	},
	{
	  value: "Macy’s  Giftcard",
	  label: "Macy’s  Giftcard",
	},
	{
	  value: "Foot Locker  Giftcard",
	  label: "Foot Locker Giftcard",
	},
	{
	  value: "Razer   Giftcard",
	  label: "Razer   Giftcard",
	},
	{
	  value: "Walmart    Giftcard",
	  label: "Walmart   Giftcard ",
	},
	{
	  value: "American Express    Giftcard",
	  label: "American Express    Giftcard",
	},
	{
	  value: "Mastercard   Giftcard ",
	  label: "Mastercard   Giftcard ",
	},
	{
	  value: "Perfect Visa Giftcard",
	  label: "Perfect Visa Giftcard",
	},
	
	{
	  value: "Amex Giftcard",
	  label: "Amex  Giftcard",
	},
	{
	  value: "Target Giftcard",
	  label: "Target Giftcard ",
	},
	{
	  value: "Razer Gold Giftcard",
	  label: "Razer Gold Giftcard",
	},
	{
	  value: "GameStop Giftcard",
	  label: "GameStop Giftcard",
	},
	{
	  value: "Other Giftcard",
	  label: "other Giftcard",
	},
	
  ];