import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Footer() {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    if (message.trim()) {
      setIsLoading(true);

      // Simulate a loading process (e.g., sending data to the server)
      setTimeout(() => {
        setIsLoading(false);
        toast.success("Message submitted");
        // Optionally, reset the form fields
        e.target.reset();
      }, 2000);
    } else {
      toast.error("Please enter a message before submitting.");
    }
  };

  return (
    <footer>
         <ToastContainer/>
         
      <div className="container px-2 py-2 border-y border-lightgray">
<form onSubmit={handleSubmit}  className="max-w-sm mx-auto">
<h2 className="font-bold text-4xl mb-6 ml-10 leading-normal"> Contact Us</h2>
<div className="mb-5">
    <label for="Name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Full Name</label>
    <input type="text" placeholder="Enter your Name" id="name" className="shadow-sm bg-[#0b112f] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" required />
  </div>
  <div className="mb-5">
    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
    <input type="email" id="email" className="shadow-sm bg-[#0b112f] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="name@xxxxx.com" required />
  </div>
  <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
  <textarea id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-[#0b112f] rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a message..."></textarea>
  <button   disabled={isLoading} type="submit" className="text-white w-full bg-blue mt-6 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
     {isLoading ? "Loading...": "Submit"}
    </button>

</form>

      </div>
      <div className="py-6 text-center">
        <p className="text-white">
          Contact our mail support : validatecards@gmail.com
        </p>
        <p className="text-white">
          © Copyright 2024 Folio-Trader LLC. All rights reserved
        </p>
      </div>
    </footer>
  );
}
