import React from "react";

import visit from "../../images/illustrations/3d-visite.png";
import walletImage from "../../images/illustrations/scrolling.webp";
import okayImage from "../../images/illustrations/validate.webp";
import arrow from "../../images/arrow.svg";
import { Fade } from "react-reveal";

export default function StepSection() {
  return (
    <section id="process" className="px-6">
      <div
        style={{ backgroundColor: "#2b0646", color: "white" }}
        className="rounded-3xl  py-20"
      >
        <div className="container mx-auto text-center">
          <h2 className="font-bold text-4xl mb-6 leading-normal">
            Get started in just a few minutes
          </h2>
          <div className="md:flex justify-center gap-20">
            <Fade up>
              <div className="text-center relative px-4">
                <div className="relative">
                  <img
                    src={visit}
                    className="mb-4 mx-auto hover:-translate-y-6 hover:scale-105 transition-all duration-300"
                    alt="visit"
                    width={210}
                    height={210}
                  />
                  <img
                    src={arrow}
                    alt="arrow"
                 
                    className="hidden md:block absolute top-1/2 -right-36"
                  />
                </div>
                <h3 className="text-2xl font-bold mb-4">Visit Validatecards.com</h3>
                <p className="text-white max-w-sm">
                Discover how you can validate your giftcard securely by visiting Validatecards official website.
                </p>
              </div>
            </Fade>
            <Fade up delay={500}>
              <div className="text-center relative px-4">
                <div className="relative md:mt-2">
                  <img
                    src={walletImage}
                    className="mb-4 mx-auto hover:-translate-y-6 hover:scale-105 transition-all duration-300"
                    alt="scroll"
                    width={210}
                    height={210}
                  />
                  <img
                    src={arrow}
                    alt=""
                    className="hidden md:block absolute top-1/2 -right-36"
                  />
                </div>
                <h3 className="text-2xl font-bold mb-4">Get started</h3>
                <p className="text-white max-w-sm">
                  Choose your preferred giftcard and input the card details accordly in the box area as specified
                </p>
              </div>
            </Fade>
            <Fade up delay={1000}>
              <div className="text-center relative px-4">
                <img
                  src={okayImage}
                  width={210}
                  height={210}
                  className="mb-4 mx-auto hover:-translate-y-6 hover:scale-105 transition-all duration-300"
                  alt=""
                />
                <h3 className="text-2xl font-bold mb-4">Validate giftcard</h3>
                <p className="text-white max-w-sm">
                Validate any gift card by entering the required details and clicking the 'Validate' button to proceed."
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}
