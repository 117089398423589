import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BsList, BsX } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";

import logo from "../../images/logo.png";
import PrimaryButton from "../buttons/PrimaryButton/index";
import SecondaryButton from "../buttons/SecondaryButton/index";
import Menus from "./Menus";
export default function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [backgroundwhite, setBackgroundWhite] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: "768px" });

  const handleWindowScroll = (e) => {
    const height = window.scrollY;
    const tresholdHeight = 50;

    if (height > tresholdHeight) {
      setBackgroundWhite(true);
    } else {
      setBackgroundWhite(false);
    }
  };

  const handleBlackScreenClick = (e) => {
    e.stopPropagation();
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (isMobile) {
      setDropdownOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    setBackgroundWhite(dropdownOpen);
  }, [dropdownOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);

    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

 

  return (
    <nav
      className={classNames(
        "fixed w-full transition-all duration-700 z-10 py-8 ",
        {
          "bg-black shadow-lg !py-3": backgroundwhite,
        }
      )}
    >
      <div className="container top-0 flex items-center justify-between px-4 mx-auto">
        <div className="flex items-center ml-6">
          <img src={logo} className="mr-6" alt="Neva" width={60} height={60} />
          <div className="hidden gap-8 mx-4 xl:flex">
            <Menus />
          </div>
        </div>
        <div className="hidden gap-4 mr-12 md:flex">
           <a href="#validate">
          <PrimaryButton >Get Started</PrimaryButton>
          </a>
        </div>
        <div className="text-2xl md:hidden">
          <button
            className="z-50 block p-4 transition-all"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {dropdownOpen ? <BsX /> : <BsList />}
          </button>

          {/* Menu dropdown */}
          <div
            className={classNames({
              "text-base left-0 top-full right-0 absolute transition-all duration-400": true,
              "invisible opacity-0": !dropdownOpen,
              "visible opacity-100": dropdownOpen,
            })}
          >
            <div
              className="left-0 h-screen bg-black bg-opacity-30"
              onClick={handleBlackScreenClick}
            >
              <div className="z-20 p-6 bg-black shadow-xl">
                {/* <div className="flex gap-4 mb-6">
                  <SecondaryButton className="w-full">Sign In</SecondaryButton>
                  <PrimaryButton className="w-full">Sign Up</PrimaryButton>
                </div> */}
                <div className="mb-4">
                  <Menus />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
