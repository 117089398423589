import classNames from "classnames";
import React, { useState } from "react";
import { BsChevronUp } from "react-icons/bs";

import faq from "../../images/illustrations/faq.webp";
import { Fade } from "react-reveal";

const FaqItem = ({ open, title, children }) => {
  const [isOpen, setIsOpen] = useState(open ? false : false);

  const iconClass = classNames({
    "transition-all duration-300": true,
    "rotate-180": isOpen,
  });

  const contentClass = classNames({
    "text-white transition-all duration-300 overflow-hidden": true,
    "h-full": isOpen,
    "h-0": !isOpen,
  });

  return (
    <div className="mb-3 border-b border-lightgray pb-3">
      <div
        className="flex justify-between py-3 cursor-pointer hover:text-primary"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <BsChevronUp className={iconClass} />
      </div>
      <div className={contentClass}>
        <p className="select-none">{children}</p>
      </div>
    </div>
  );
};

export default function FaqSection() {
  return (
    <section id="faq" className="container mx-auto py-28">
      <div className="grid md:grid-cols-2">
        <div className="mb-4">
          <Fade up duration={1000}>
            <img src={faq} alt="FAQ"  width={500} height={500}/>
          </Fade>
        </div>
        <div className="flex justify-center">
          <div className="">
            <span className="text-primary">SUPPORT</span>
            <h2 className="font-bold text-4xl mb-6 leading-normal">
              Frequently asked questions
            </h2>
            <div className="my-6">
              <FaqItem open={true} title="What is a gift card?">
              A gift card is a prepaid debit card issued by a retailer for online purchases. 
              It can also be use for shopping at stores or buying games and music online. 
              A gift card can be sent to loved ones as a birthday present or any other occasionsA 
              gift card is a prepaid debit card issued by a retailer for online purchases. 
              It can also be use for shopping at stores or buying games and music online. 
              A gift card can be sent to loved ones as a birthday present or any other occasions
              </FaqItem>
              <FaqItem open={false} title="How can I Validate my gift card?">
              You can validate your gift card status by using our Validatecards platform,
               entering the card number and PIN.
              </FaqItem>
              <FaqItem open={false} title="How safe is it to use a gift card?">
              Gift cards are generally safe to use if purchased from reputable sources. 
              Validatecards helps ensure the authenticity and validity of your card, providing added security.
              </FaqItem>
              <FaqItem open={false} title="What should I do if my gift card is lost or stolen?">
              If your gift card is lost or stolen, contact the issuing retailer immediately. 
              Some retailers may offer replacement cards if you have the original purchase receipt.
              </FaqItem>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
