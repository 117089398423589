import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import creditCard from "../../images/illustrations/cards.png";
import { Fade } from "react-reveal";

export default function CreditCardSection() {
  return (
    <section className="container mx-auto py-32">
      <div style={{ color: "white" }} className="grid md:grid-cols-2 gap-6">
        <div className="mb-12 px-4 md:px-4">
          <Fade up>
            <img src={creditCard} alt="" />
          </Fade>
        </div>
        <div className="flex justify-center">
          <div className="max-w-md">
            <h2 className="font-bold text-4xl mb-3 leading-normal">
            In Giftcard validation, we are secure & trustworthy
            </h2>
            <p className="text-white text-[15px]">
            Validatecards is one of the largest and safest platforms out there. 
            The best giftcard validator provide a good mix of security tools and 
            user-facing features at no cost.
            </p>

            <ul className="my-6">
              <li className="mb-2">
                <BsCheckCircleFill className="text-primary inline mr-2" />
                High performance
              </li>
              <li className="mb-2">
                <BsCheckCircleFill className="text-primary inline mr-2" />
                Secured and Encrypted
              </li>
              <li className="mb-2">
                <BsCheckCircleFill className="text-primary inline mr-2" />
                Self-governed managed all and easy giftcard validation.
              </li>
            </ul>

            {/* <SecondaryButton>Join the waitlist</SecondaryButton> */}
          </div>
        </div>
      </div>
    </section>
  );
}
