import React from 'react';

import DropdownMenu from './DropdownMenu';

export default function Menus() {
  return (
    <>
      <div className="w-full">
        <a href="#home" className='block py-2 hover:text-primary'>Home</a>
      </div>
      <div className="w-full">
        <a href="#validate" className='block py-2 hover:text-primary'>Validate</a>
      </div>
      <div className="w-full">
        <a href="#process" className='block py-2 hover:text-primary'>Process</a>
      </div>
      <div className="w-full">
        <a href="#security" className='block py-2 hover:text-primary'>Security</a>
      </div>
      <div className="w-full">
        <a href="#testimonial" className='block py-2 hover:text-primary'>Testimonial</a>
      </div>
      <div className="w-full">
        <a href="#faq" className='block py-2 hover:text-primary'>Faq</a>
      </div>
      
    </>
  );
}
