import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";

import banks from "../../images/illustrations/banks.png";
import { Fade } from "react-reveal";

export default function SecuritySection() {
  return (
    <section id="security" className="container mx-auto py-28">
      <div style={{ color: "white" }} className="grid md:grid-cols-2">
        <div>
          <Fade up>
            <img src={banks} alt="" />
          </Fade>
        </div>
        <div className="flex justify-center">
          <div className="max-w-xl">
            <h2 className="font-bold text-4xl mb-6 leading-normal">
              Industry-leading security from day one
            </h2>
            <ul className="my-6">
              <li className="mb-4">
                <span>
                  <BsCheckCircleFill className="text-primary inline mr-2" />
                  Safety, security and compliance
                </span>
                <p className="text-white mt-2">
                  ValidateCard is a licensed and trusted company that undergoes
                  advance security exams and is subject to the cybersecurity audits
                  conducted by the Department of validotor's Services.
                  Learn more about our commitment to security.
                </p>
              </li>
              <li className="mb-4">
                <span>
                  <BsCheckCircleFill className="text-primary inline mr-2" />
                  Hardware security keys
                </span>
                <p className="text-white mt-2">
                  With ValidateCard you can validate your giftcard Authenticity with our hardware security.
                </p>
              </li>
              <li className="mb-6">
                <span>
                  <BsCheckCircleFill className="text-primary inline mr-2" />
                  Secured and Encrypted
                </span>
                <p className="text-white">
                We at ValidateCard makes sure customers giftcard details are secured and encrypted. 
                No attacker or false identity can intercept your verification operations
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
