import React from "react";

import partner1 from "../../images/partners/walmart.png";
import partner2 from "../../images/partners/bestbuy.png";
import partner3 from "../../images/partners/terget.png";
import partner4 from "../../images/partners/gamestop.webp";
import { Fade } from "react-reveal";

export default function PartnerSection() {
  return (
    <section className="px-6">
      <div
        style={{ backgroundColor: "#60A5FFCF", color: "white" }}
        className="rounded-3xl  py-20"
      >
        <div className="container mx-auto">
          <div className="text-center mb-4">
            <h2 className="text-3xl font-bold mb-4">
              Trusted Partners Worldwide
            </h2>
            <p className="text-white">
            These are some of the retailers you can purchase gift cards from that we trust, <br/>
            there are various trusted retailers online also
            </p>
          </div>
          <div className="mx-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
              <Fade up>
                <img src={partner1} className="basis-1/2" alt="walmert"   width={120} height={120}/>
              </Fade>
              <Fade up delay={600}>
                <img src={partner4} className="basis-1/2" alt="gamestop" width={120} height={120} />
              </Fade>
              <Fade up delay={200}>
                <img src={partner2} className="basis-1/2" alt="bestbuy" width={80} height={80} />
              </Fade>
              <Fade up delay={400}>
                <img src={partner3} className="basis-1/2" alt="terget" width={60} height={60} />
              </Fade>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
